import { formatGarageTimeISO } from '@/utils/garageTimes'
import { deepClone } from '@/utils/deepClone'
import { phoneFormatFilter } from '@/utils/phone'

export function buildModel(
  vehicleAssignments,
  vehicles,
  driverCount = 0,
  tripVehicleGroups = null
) {
  const UNASSIGNED_VEHICLE_TYPE_ID = 99

  if (vehicles == null) return []
  let model = []
  let emptyAssignmentObj = {
    vehicleAssignmentId: null,
    vehicle: {
      vehicleId: null,
      name: null,
    },
    driverAssignments: [
      {
        tripAssignmentId: null,
        driver: {
          userId: null,
          name: null,
        },
      },
    ],
  }
  if (tripVehicleGroups === null) {
    model[0] = {
      tripVehicleGroupId: null,
      name: '',
      vehicleAssignments: [],
    }
    // Build the empty model, unpopulated with existing trip assignments
    vehicles.map((vehicleType) => {
      for (let i = 0; i < vehicleType.quantity; i++) {
        let newEmptyAssignment = deepClone(emptyAssignmentObj)
        newEmptyAssignment.vehicleTypeId = vehicleType.vehicleType.id
        newEmptyAssignment.vehicleTypeLabel = vehicleType.vehicleType.label
        newEmptyAssignment.tripVehicleGroupId = vehicleType.tripVehicleGroupId
        model[0].vehicleAssignments.push(newEmptyAssignment)
      }
    })

    // For each existing assignment, find an
    // empty object in the model with the same vehicleType
    // to populate
    vehicleAssignments.map((assignment) => {
      let createNewAssignment = false
      let emptyAssignment

      if (
        assignment.vehicleTypeId === UNASSIGNED_VEHICLE_TYPE_ID ||
        (assignment?.vehicle?.vehicleName &&
          assignment?.vehicle?.vehicleName.toLowerCase() ===
            'vehicle unassigned')
      ) {
        emptyAssignment = model[0]?.vehicleAssignments.find(
          (obj) => !obj.vehicleAssignmentId
        )
      } else {
        emptyAssignment = model[0]?.vehicleAssignments.find(
          (obj) =>
            Number(obj.vehicleTypeId) === Number(assignment.vehicleTypeId) &&
            !obj.vehicleAssignmentId
        )
      }

      if (!emptyAssignment) {
        emptyAssignment = deepClone(emptyAssignmentObj)
        emptyAssignment = {
          ...emptyAssignment,
          vehicleTypeId: assignment.vehicleTypeId,
          vehicleTypeLabel: assignment.vehicleTypeLabel,
        }
        createNewAssignment = true
      }

      emptyAssignment.vehicleAssignmentId = assignment.tripAssignmentId
      emptyAssignment.vehicle = assignment.vehicle
      emptyAssignment.vehicle.name = `${assignment.vehicle.vehicleName} ${assignment.vehicle.vehicleModel}`

      let driverAssignments = assignment.driverAssignments.map((driver) => {
        driver.driver.name =
          driver.driver.firstName + ' ' + driver.driver.lastName
        return driver
      })

      if (driverAssignments.length) {
        emptyAssignment.driverAssignments = driverAssignments
      }

      if (createNewAssignment) {
        model[0].vehicleAssignments.push(emptyAssignment)
      }
    })

    let currentDriverCount = model[0].vehicleAssignments.reduce(
      (prev, curr) => prev + curr.driverAssignments.length,
      0
    )
    let driversToAdd = driverCount - currentDriverCount
    if (driversToAdd > 0 && model[0].vehicleAssignments.length) {
      for (let i = 0; i < driversToAdd; i++) {
        model[0].vehicleAssignments[
          i % model[0].vehicleAssignments.length
        ].driverAssignments.push({
          tripAssignmentId: null,
          driver: {
            userId: null,
            name: null,
          },
        })
      }
    }
  } else {
    tripVehicleGroups.map((group) => {
      let tripVehicleGroupObj = {
        tripVehicleGroupId: group.tripVehicleGroupId,
        name: group.name,
        vehicleAssignments: [],
      }
      group.tripVehicles.map((vehicle) => {
        for (let i = 0; i < vehicle.quantity; i++) {
          let newEmptyAssignment = deepClone(emptyAssignmentObj)
          newEmptyAssignment.vehicleTypeId = vehicle.vehicleType.id
          newEmptyAssignment.vehicleTypeLabel = vehicle.vehicleType.label
          newEmptyAssignment.tripVehicleGroupId = vehicle.tripVehicleGroupId
          tripVehicleGroupObj.vehicleAssignments.push(newEmptyAssignment)
        }
      })
      model.push(tripVehicleGroupObj)
    })

    vehicleAssignments.map((assignment) => {
      let foundGroup = model.find(
        (group) => group.tripVehicleGroupId === assignment.tripVehicleGroupId
      )
      if (!foundGroup) {
        return
      }
      let emptyAssignment = foundGroup.vehicleAssignments.find(
        (obj) =>
          Number(obj.vehicleTypeId) === Number(assignment.vehicleTypeId) &&
          !obj.vehicleAssignmentId
      )

      if (!emptyAssignment) {
        return
      }
      emptyAssignment.vehicleAssignmentId = assignment.tripAssignmentId
      emptyAssignment.vehicle = assignment.vehicle
      emptyAssignment.vehicle.name = `${assignment.vehicle.vehicleName} ${assignment.vehicle.vehicleModel}`

      let driverAssignments = assignment.driverAssignments.map((driver) => {
        driver.driver.name =
          driver.driver.firstName + ' ' + driver.driver.lastName
        return driver
      })
      if (driverAssignments.length) {
        emptyAssignment.driverAssignments = driverAssignments
      }
    })

    let currentDriverCount = model.reduce(
      (acc, vehicleAssignment) => acc + vehicleAssignment.vehicleAssignments.reduce(
        (acc2, assignment) => acc2 + assignment.driverAssignments.length, 0), 0);
    let driversToAdd = driverCount - currentDriverCount
    if (driversToAdd > 0 && !!model.length) {
      for (let i = 0; i < driversToAdd; i++) {
        if (!!model[i % model.length].vehicleAssignments.length) {
          model[i % model.length].vehicleAssignments[
            i % model[i % model.length].vehicleAssignments.length
          ].driverAssignments.push({
            tripAssignmentId: null,
            driver: {
              userId: null,
              name: null,
            },
          })
        }
      }
    }
  }

  return model
}

// Take the existing trip, and format its times. Return
export const buildValidateTripPayload = (tripToValidate) => {
  let trip = { ...tripToValidate }

  trip.stops.map((stop) => {
    let stopTimeZone = stop?.address?.timeZone
    if (stop.pickupDatetime && stop.pickupDate && stop.pickupTime) {
      stop.pickupDatetime = formatGarageTimeISO(
        stop.pickupDate,
        stop.pickupTime,
        stopTimeZone
      )
    }

    if (stop.dropoffDatetime && stop.dropoffDate && stop.dropoffTime) {
      stop.dropoffDatetime = formatGarageTimeISO(
        stop.dropoffDate,
        stop.dropoffTime,
        stopTimeZone
      )
    }

    if (stop.active == null || stop.active == undefined) {
      stop.active = true
    }
  })

  return trip
}

export const isUnassignedVehicleAssignment = (vehicleAssignment) => {
  if (vehicleAssignment.vehicleTypeId === 99) return true

  if (vehicleAssignment.vehicle?.simulated) {
    return true
  }

  if (!vehicleAssignment.vehicle?.vehicleName) {
    return false
  }

  if (
    vehicleAssignment.vehicle.vehicleName.toLowerCase() === 'vehicle unassigned'
  ) {
    return true
  }

  return false
}

export const getDriverPhoneNumber = (driver) => {
  if (!driver.phone && !driver.phoneNumber) {
    return null
  }

  const phoneNumber = driver.phone || driver.phoneNumber
  return phoneFormatFilter(phoneNumber)
}
