
import { Vue, Prop, Component, Watch, Inject } from 'vue-property-decorator'
import CreditCardSelectorCard from '@/components/CreditCardSelectorCard.vue'
import { isRequired, isNotEmpty } from '@/utils/validators'
import {
  getCardType,
  getCardNumberMask,
  getCardExpirationLength,
  getCardExpirationRawValue,
  getCardExpirationMask,
} from '@/utils/creditCard'
import { v4 } from 'uuid'
import { Address } from '@/models/dto'
import { finixConfig } from '@/utils/env'
import { SplitFeatureFlag, SplitKeyType, PaymentMethodTypeKey, PaymentGatewayTypeKey, CompanyId } from '@/utils/enum'
import { SimplifiedPaymentGateway } from '@/models/dto/CompanyPaymentGateway'

const finixOptions = {
  showAddress: true,
  showLabels: true,
  labels: {
    name: "Name",
    security_code: "Security Code",
    address_line1: "Address"
  },
  showPlaceholders: false,
  hideFields: [
    "address_line1",
    "address_state",
    "address_city",
    "address_postal_code",
    "address_line2",
    "address_region",
    "address_country",
  ],
  requiredFields: [
    "name",
  ],
  hideErrorMessages: false,
  errorMessages: {
    name: "Please enter a valid name",
    address_city: "Please enter a valid city",
  },
  styles: {
    default: {
      color: "#000",
      border: "1px solid #eaebee",
      borderRadius: "4px",
      padding: "8px 16px",
      fontFamily: "Helvetica",
      fontSize: "16px",
    },
    success: {
      color: "#000",
    },
    error: {
      color: "#000",
    },
  },
}

@Component({ components: { CreditCardSelectorCard }})
export default class CreditCardSelector extends Vue {

  @Inject({ from: 'isInvoice', default: false }) readonly isInvoice: boolean

  @Prop({ type: Object, default: () => {} }) value: any
  @Prop({ type: Array, required: true }) savedCards: any[]
  @Prop({ type: Boolean,  }) defaultToAddNewCard: boolean
  @Prop({ type: String, default: 'Add a Card' }) newCardText: String
  @Prop({ type: Boolean, default: false }) expandAddressSelector: boolean
  @Prop({ type: Boolean,  }) isCharterUpCheckout: boolean
  @Prop({ type: Boolean, default: true }) showGatewayType: boolean
  @Prop({ type: Boolean, default: false }) isCustomerCancel: boolean
  @Prop({ type: Number, default: null }) defaultSelectedCardIndex: number
  @Prop({ type: Object, default: () => ({ cardNumber: [], expirationDate: [] }) }) errorMessages: any

  @Watch('savedCards')
  onSavedCardsChanged(cards: any[]) {
    this.newCardSelected = !cards.length;
  }

  @Watch('selectedCard', { deep: true })
  onSelectedCardChanged() {
    this.$emit('input', this.selectedCard);
  }

  @Watch('newCardSelected')
  onNewCardSelectedChanged() {
    this.$emit('new-card-selected', this.newCardSelected);
  }

  @Watch('value', { immediate: true, deep: true })
  onValueChanged(newValue: any) {
    this.selectedCardIndex = this.savedCards.findIndex(
      (card) =>
        (newValue?.operatorPaymentProfileId &&
          card.operatorPaymentProfileId === newValue.operatorPaymentProfileId) ||
        (newValue?.customerPaymentProfileId &&
          card.customerPaymentProfileId === newValue.customerPaymentProfileId)
    );
  }

  v4 = v4
  isRequired = isRequired
  isNotEmpty = isNotEmpty

  finixForm = null
  selectedCardIndex = -1
  newCardSelected = false
  saveForFuturePayments = false
  addressIsSelected = false
  addressErrors = []
  newCard = {
    cardNumber: null,
    name: null,
    expiration: null,
    securityCode: null,
    address: {
      addressName: null,
      city: null,
      completeAddress: null,
      lat: null,
      lng: null,
      postalCode: null,
      state: null,
      country: null,
      street1: null,
      timeZone: null,
    },
  }

  get normalizedErrorMessages(): { cardNumber: [], expirationDate: [] } {
    let cardNumber = this.errorMessages.cardNumber || []
    let expirationDate = this.errorMessages.expirationDate || []

    return {
      cardNumber,
      expirationDate,
    }
  }

  get selectedCard(): { newCard: any, saveForFuturePayments: boolean} {
    if (this.selectedCardIndex >= 0) {
      return this.savedCards[this.selectedCardIndex]
    }
    if (this.newCardSelected) {
      return {
        newCard: this.newCard,
        saveForFuturePayments: this.saveForFuturePayments,
      }
    }
    return null
  }

  get cardNumberMask(): string {
    return getCardNumberMask(this.cardType)
  }

  get cardType(): string {
    return getCardType(this.newCard.cardNumber)
  }

  get cardExpirationMask(): string {
    return getCardExpirationMask(this.cardExpirationLength)
  }

  get cardExpirationRawValue(): string {
    return getCardExpirationRawValue(this.newCard.expiration)
  }

  get cardExpirationLength(): string {
    return getCardExpirationLength(this.cardExpirationRawValue)
  }

  mounted(): void {
    if (!this.savedCards.length) {
      this.addNewCard()
    }
    this.initFinixForm()
  }

  initFinixForm(): void {
    //@ts-ignore
    this.finixForm = window.Finix.CardTokenForm("finix-form", finixOptions);
  }

  async getFinixPaymentGateway(): Promise<SimplifiedPaymentGateway> {
    const paymentGatewayResponse = await this.$store.dispatch('payments/getAllPaymentGateways')
    const finixGateway = paymentGatewayResponse
      .data
      .paymentGateways
      .filter((paymentGateway) => paymentGateway.companyId === CompanyId.CharterUP)
      .filter((paymentGateway) => paymentGateway.checkoutPageId === null)
      .filter((paymentGateway) => paymentGateway.paymentGatewayTypeKey === PaymentGatewayTypeKey.Finix)
      ?.[0]
    return {
      id: finixGateway?.companyPaymentGatewayId,
      key: finixGateway?.paymentGatewayTypeKey,
    }
  }

  async tokenizeFinixCard(): Promise<string> {
    const { environment, applicationId } = finixConfig();

    return new Promise((resolve, reject) => {
      this.finixForm.submit(environment, applicationId, function (err, res) {
        if (err) {
          reject(err); // Reject the promise if there's an error
        } else {
          const tokenData = res.data || {};
          const token = tokenData.id;
          resolve(token); // Resolve the promise with the token
        }
      });
    });
  }

  async tokenizeCard(): Promise<{ paymentGateway: SimplifiedPaymentGateway, tokens: string[], formattedPaymentInfo: any }> {

    let formattedPaymentInfo = this.formatCardPaymentInfo()
    const finixPaymentGateway = await this.getFinixPaymentGateway()
    const token = await this.tokenizeFinixCard()
    return {
      tokens: [token],
      paymentGateway: finixPaymentGateway,
      formattedPaymentInfo
    }

  }

  formatCardPaymentInfo(): any {
    const { name, cardNumber, expiration: exp_date } = this.newCard
    const {
      postalCode,
      timeZone: time_zone,
      addressName,
      ...addressFields
    } = this.newCard.address

    let mask = cardNumber?.substring(cardNumber.length - 4)
    const formatted = {
      billing: {
        address: {
          ...addressFields,
          name: addressName,
          postalCode,
          time_zone,
        },
        exp_date,
        mask,
        name,
        cardholderName: name,
        type_label: this.cardType
      },
      showOnCharterUp: this.saveForFuturePayments
    }

    return formatted
  }

  selectCard(cardIndex: number): void {
    this.selectedCardIndex = cardIndex
    this.newCardSelected = false
  }

  addNewCard(): void {
    this.selectedCardIndex = -1
    this.newCardSelected = true
    this.initFinixForm()
  }

  clearNewCard(): void {
    this.newCard = {
      cardNumber: null,
      name: null,
      expiration: null,
      securityCode: null,
      address: {
        addressName: null,
        city: null,
        completeAddress: null,
        lat: null,
        lng: null,
        postalCode: null,
        state: null,
        country: null,
        street1: null,
        timeZone: null,
      },
    }
    this.newCardSelected = false
  }

  handleAddressSelect(event: { place: Address }): void {
    this.newCard.address = {
      addressName: event.place.addressName || null,
      city: event.place.city || null,
      completeAddress: event.place.completeAddress || null,
      lat: event.place.lat || null,
      lng: event.place.lng || null,
      postalCode: event.place.postalCode || null,
      state: event.place.state || null,
      country: event.place.country || null,
      street1: event.place.street1 || null,
      timeZone: event.place.timeZone || null,
    }
    let hasPostalCode = !!this.newCard.address.postalCode
    if (!hasPostalCode) {
      this.addressErrors = ['Address With Zip Code is Required']
    } else {
      this.addressErrors = []
    }
  }

  handleAddressClear(): void {
    this.newCard.address = {
      addressName: null,
      city: null,
      completeAddress: null,
      lat: null,
      lng: null,
      postalCode: null,
      state: null,
      country: null,
      street1: null,
      timeZone: null,
    }

    this.addressErrors = []
    this.addressIsSelected = false
  }

  validate(): boolean {
    if (this.newCardSelected) {

      let isValid = true
      if (this.expandAddressSelector) {
        let isAddressSelectorValid = (this.$refs.expandableAddressSelector as any).validate()
        isValid = isValid && isAddressSelectorValid
      } else {
        let hasPostalCode = !!this.newCard.address.postalCode
        if (!hasPostalCode) {
          this.addressErrors = ['Address With Zip Code is Required']
        }
        isValid = isValid && hasPostalCode
      }

      return isValid
    } else if (this.value?.customerPaymentProfileId) {
      return true
    }
    return false
  }
}
